
import gsap from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);
import Swiper, { Navigation, Pagination } from 'swiper';

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    // Animations
    gsap.utils.toArray('.animate').forEach(item => {
        ScrollTrigger.create({
            trigger: item,
            start: 'top 75%',
            end: 'bottom center',
            toggleClass: 'animate--active',
            once: true,
        });
    });


    // Draw line - banner graphic
    let banner = document.querySelector('.banner');
    if (banner) {
        let pl = gsap.timeline({
            scrollTrigger: {
                trigger: '.rose-line',
                scrub: 4,
                start: 'top center',
                end: 'bottom center',
                // toggleClass: 'active',
                // once: true,
            }
        });
        pl.from('.rose-line', {drawSVG: "0% 10%"}).to('.rose-line', {drawSVG: "0% 100%"})
    }

    // Draw line - featured points
    let draw = document.querySelector('.line');
    if (draw) {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.line',
                scrub: 8,
                start: 'top 75%',
                end: 'bottom center',
                // once: true,
            }
        });
        tl.from('.line', {drawSVG: "0% 0%"}).to('.line', {drawSVG: "0% 100%"})
    }


    // Dots - featured points
    gsap.utils.toArray('.featured-points__dot').forEach(dot => {
        ScrollTrigger.create({
            trigger: dot,
            start: 'top 100%',
            end: 'bottom center',
            toggleClass: 'featured-points__dot--active',
            once: true,
        });
    });


    // Swiper
    Swiper.use([Navigation, Pagination]);

    const swiper = new Swiper('.swiper-container', {
        // direction: 'vertical',
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                // return '<span class="' + className + '">' + titles[index] + '</span>';
                return '<span class="' + className + '"></span>';
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });


    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target;
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!('accordionAllowMultiple' in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Team lightbox
    let teamMembers = document.querySelectorAll('.team');
    let teamContents = document.querySelectorAll('.team-modal');
    let teamOverlay = document.querySelector('.team-overlay');
    let teamCloseOverlay = document.querySelectorAll('.team-modal__close');


    function getTeamMemberDetails(index) {
        teamContents.forEach(item => {
            if(item.getAttribute('data-team') == index) {
                item.classList.add('active');
            }
        })
        teamOverlay.classList.add('active');
    }


    if(teamMembers) {
        teamMembers.forEach(item => {
            item.addEventListener('click', function() {
                getTeamMemberDetails(this.getAttribute('data-team'));
            })
        })
    }

    function removeOverlay() {
        teamContents.forEach(item => {
            item.classList.remove('active');
        })
        teamOverlay.classList.remove('active');
    }

    if(teamOverlay) {
        teamOverlay.addEventListener('click', function() {
            removeOverlay();
        })
    }

    if(teamCloseOverlay) {
        teamCloseOverlay.forEach(item => {
            item.addEventListener('click', function() {
                removeOverlay();
            })
        })
    }


    // Mobile navigation
    const navTriggers = document.querySelectorAll('.js-nav-btn');
    const panelTriggers = document.querySelectorAll('.js-panel-btn');
    const mobNav = document.querySelector('.mobile');
    const mobNavIcon = document.querySelector('.nav-icon');
    const mobNavOverlay = document.querySelector('.mobile-overlay');

    if(navTriggers){
        navTriggers.forEach(function(navTrigger) {
            bindEvent(navTrigger, "click", function() {
                event.preventDefault();
                document.body.classList.toggle('modal-open');
                mobNavIcon.classList.toggle('nav-icon--active');
                mobNav.classList.toggle('mobile--active');
                navTrigger.setAttribute('aria-expanded', navTrigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }, false);
        });
    }


    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
